import {
  Box,
  Button,
  ChakraProvider,
  Icon,
  useDisclosure,
} from "@chakra-ui/react"
import * as React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { RiCloseCircleLine } from "react-icons/ri"

import Header from "./header"
import Footer from "../components/footer"
import CustomFonts from "../theme/customFonts"
import theme from "../theme"
import HomesiteEvaluationPopup from "./homesiteEvaluationPopup"
import DownloadEbookButton from "../components/downloadEbook"
import { useSessionStorage } from "@avldev/gatsby-theme-core-ui/hooks"

interface PageContainerProps {
  children: React.ReactNode
  showAssociations?: boolean
  showNewsletter?: boolean
}

function PageContainer({
  children,
  showAssociations,
  showNewsletter,
}: PageContainerProps): JSX.Element {
  gsap.registerPlugin(ScrollTrigger)

  const popup = useDisclosure()
  const [fired, setFired] = useSessionStorage(`evalPopup`, false)
  const [ebook, setEbook] = useSessionStorage(`ebook`, false)
  const [minimized, setMinimized] = useSessionStorage(`ebookMin`, false)
  const [hasInteracted, setHasInteracted] = useSessionStorage(
    `hasInteracted`,
    false
  )
  const popupTimerRef = React.useRef<any>()
  const ebookRef = React.useRef<HTMLDivElement>()
  const pixelRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    if (!hasInteracted) {
      ScrollTrigger.create({
        end: `top top-=${window.innerHeight * 0.33}px`,
        once: true,
        onToggle: ({ progress }) => {
          if (progress === 1) {
            setHasInteracted(true)
          }
        },
        start: `top top+=72px`,
      })
    }
  }, [hasInteracted])

  React.useEffect(() => {
    if (hasInteracted) {
      if (!fired) {
        clearTimeout(popupTimerRef.current)
        popupTimerRef.current = setTimeout(openPopup, 7000)
      }

      if (!ebook) {
        gsap.to(ebookRef.current, {
          duration: 2,
          ease: `bounce.out`,
          right: `-1px`,
          onComplete: () => setEbook(true),
        })
      } else {
        gsap.set(ebookRef.current, {
          right: minimized
            ? `-${ebookRef.current.clientWidth * 0.84}px`
            : `-1px`,
        })
      }
    }
  }, [fired, ebook, hasInteracted])

  const openPopup = (): void => {
    setFired(true)
    setTimeout(popup.onOpen, 3000)
  }

  const toggleEbookButtonVisibility = (): void => {
    gsap.to(ebookRef.current, {
      duration: 0.3,
      ease: `power2.out`,
      right: minimized ? `-1px` : `-${ebookRef.current.clientWidth * 0.84}px`,
      onComplete: () => {
        setMinimized(!minimized)
      },
    })
  }

  const maximizeOnModalOpen = (): void => {
    if (minimized) {
      toggleEbookButtonVisibility()
    }
  }

  const minimizeOnModalClose = (): void => {
    if (!minimized) {
      toggleEbookButtonVisibility()
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <CustomFonts />
      <div
        ref={pixelRef}
        style={{
          height: 0,
          left: 0,
          position: `absolute`,
          top: 0,
          width: 0,
          zIndex: -1,
        }}
      />
      <Header />
      <Box
        id="content"
        mt="4.5rem"
        mx="auto"
        minH="76vh"
        maxW="100vw"
        overflowX="hidden"
      >
        {children}
        <Box
          bottom="-1px"
          pos="fixed"
          ref={ebookRef}
          right="-500px"
          role="group"
          zIndex="5"
        >
          <Button
            aria-label="Toggle 'Download Plan Book' button's visibility"
            borderRadius="100%"
            boxSize="22px"
            left="-8px"
            onClick={toggleEbookButtonVisibility}
            pos="absolute"
            top="-8px"
            variant="unstyled"
            zIndex={4}
            _focus={{ boxShadow: 0 }}
          >
            <Icon
              as={RiCloseCircleLine}
              bg="teal.700"
              borderRadius="100%"
              boxSize="22px"
              color="white"
              cursor="pointer"
              opacity={[1, 1, 1, 0]}
              transform={minimized ? `rotate(45deg)` : `rotate(0deg)`}
              transitionDuration="100ms, 300ms"
              transitionProperty="opacity, transform"
              _groupHover={{ opacity: 1 }}
            />
          </Button>
          <DownloadEbookButton
            onCloseModal={minimizeOnModalClose}
            onOpenModal={maximizeOnModalOpen}
          />
        </Box>
      </Box>
      <Footer
        showAssociations={showAssociations}
        showNewsletter={showNewsletter}
      />
      <HomesiteEvaluationPopup {...popup} />
    </ChakraProvider>
  )
}

export default PageContainer
