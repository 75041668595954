import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureProps,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function HomesiteEvaluationPopup({
  isOpen,
  onClose,
}: UseDisclosureProps): JSX.Element {
  return (
    <Modal allowPinchZoom isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent>
        <ModalHeader borderTopRadius="lg" m={0} p={0}>
          <Box pos="relative" w="100%">
            <StaticImage
              alt="A Sundog home on a steep, waterfront lot in Tuckaseegee, NC."
              className="squared"
              layout="fixed"
              height={300}
              src="../images/home-on-bear-lake.jpg"
              style={{
                borderTopLeftRadius: `var(--chakra-radii-md)`,
                borderTopRightRadius: `var(--chakra-radii-md)`,
                width: `100%`,
              }}
            />
            <Flex
              align="center"
              bg="blackAlpha.600"
              flexDir="column"
              h="300px"
              justify="center"
              left="0"
              pos="absolute"
              textAlign="center"
              top="0"
              w="100%"
            >
              <Text
                color="white"
                fontSize="lg"
                fontStyle="italic"
                fontWeight="light"
                mb={-1}
              >
                Request Your
              </Text>
              <Text
                borderBottom="2px solid red"
                color="white"
                fontFamily="heading"
                fontSize="3xl"
                fontWeight="bold"
              >
                Free Homesite Evaluation
              </Text>
            </Flex>
            <Image
              alt="Energy Star"
              bottom="20px"
              h="50px"
              pos="absolute"
              right="10px"
              src="/energy-star-partner.svg"
            />
            <Image
              alt="Energy Star"
              bottom="20px"
              filter="brightness(1000%)"
              h="50px"
              left="10px"
              pos="absolute"
              src="/green-built-alliance-partner.svg"
            />
            <ModalCloseButton color="gray.300" size="lg" />
          </Box>
        </ModalHeader>
        <ModalBody mx="auto" py={4} w={[`95%`, `90%`]}>
          <Text>
            Sundog Homes is proud to offer{" "}
            <b>free, no-obligation homesite evaluations</b> to current and
            prospective landowners that plan to build within 12 months.
            <br />
            <br />
            Our Energy Star and Certified Green builders will spend up to 2
            hours inspecting the land of your choosing, and will then help you
            to determine the floor plan and home placement that best suit both
            your property and your budget.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center" mb={4}>
          <Button
            as={GatsbyLink}
            colorScheme="green"
            mx={3}
            to="/free-homesite-evaluations/"
          >
            Request Evaluation
          </Button>
          <Button mx={3} onClick={onClose} variant="link">
            No, thanks
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default HomesiteEvaluationPopup
