type NavItem = {
  label: string
  slug: string
}

interface MobileNavItem extends NavItem {
  children?: MobileNavItem[]
}

interface INavGroup {
  name: string
  members: NavItem[]
}

interface IDesktopNavItems {
  firstLevel: NavItem[]
  secondLevel: NavItem[]
  groups?: INavGroup[]
}

const MobileNavItems: MobileNavItem = {
  label: `Main`,
  slug: `#`,
  children: [
    {
      label: `Browse Our Plans`,
      slug: `/home-plans/`,
    },
    {
      label: `Get Started with Sundog`,
      slug: `/get-started/`,
    },
    {
      label: `Visit Our Design Studio`,
      slug: `/design-studio/`,
    },
    {
      label: `See Our Portfolio`,
      slug: `/portfolio/`,
    },
    {
      label: `Meet Our Team`,
      slug: `/team/`,
    },
    {
      label: `Working With Sundog`,
      slug: `#`,
      children: [
        {
          label: `Learn How We Work`,
          slug: `/working-with-sundog/`,
        },
        {
          label: `Hear From Our Clients`,
          slug: `/client-stories/`,
        },
        {
          label: `Request a Homesite Evaluation`,
          slug: `/free-homesite-evaluations/`,
        },
      ],
    },
    {
      label: `Get to Know Us`,
      slug: `#`,
      children: [
        {
          label: `Browse Our Blog`,
          slug: `/blog/`,
        },
        {
          label: `Read Our Company Story`,
          slug: `/story/`,
        },
        {
          label: `Learn About Our Values`,
          slug: `/values/`,
        },
      ],
    },
    {
      label: `Get In Touch With Us`,
      slug: `#`,
      children: [
        {
          label: `Call or Message Us`,
          slug: `/contact#call/`,
        },
        {
          label: `Schedule a Consultation`,
          slug: `/schedule-consultation/`,
        },
        {
          label: `Visit Us In West Asheville`,
          slug: `/location/`,
        },
      ],
    },
  ],
}

const DesktopNavItems: IDesktopNavItems = {
  firstLevel: [
    {
      label: `View Our Plans`,
      slug: `/home-plans/`,
    },
    {
      label: `Get Started`,
      slug: `/get-started/`,
    },
    {
      label: `Visit Our Design Studio`,
      slug: `/design-studio/`,
    },
    {
      label: `See Our Portfolio`,
      slug: `/portfolio/`,
    },
    {
      label: `Meet Our Team`,
      slug: `/team/`,
    },
  ],
  secondLevel: [
    {
      label: `Difference`,
      slug: `/sundog-difference/`,
    },
    {
      label: `Portfolio`,
      slug: `/portfolio/`,
    },
    {
      label: `Contact`,
      slug: `/contact/`,
    },
  ],
  groups: [
    {
      name: `Working With Sundog`,
      members: [
        {
          label: `Learn How We Work`,
          slug: `/working-with-sundog/`,
        },
        {
          label: `Hear From Our Clients`,
          slug: `/client-stories/`,
        },
        {
          label: `Request a Homesite Evaluation`,
          slug: `/free-homesite-evaluations/`,
        },
      ],
    },
    {
      name: `Get To Know Us`,
      members: [
        {
          label: `Browse Our Blog`,
          slug: `/blog/`,
        },
        {
          label: `Read Our Company Story`,
          slug: `/story/`,
        },
        {
          label: `Learn About Our Values`,
          slug: `/values/`,
        },
      ],
    },
    {
      name: `Get In Touch With Us`,
      members: [
        {
          label: `Call or Message Us`,
          slug: `/contact/`,
        },
        {
          label: `Schedule a Consultation`,
          slug: `/schedule-consultation/`,
        },
        {
          label: `Visit Us In West Asheville`,
          slug: `/location/`,
        },
      ],
    },
  ],
}

export { DesktopNavItems, MobileNavItems, NavItem, INavGroup, MobileNavItem }
