import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UseDisclosureProps,
} from "@chakra-ui/react"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight, FaBook, FaDownload } from "react-icons/fa"
import gsap from "gsap"

import NewsletterForm from "./newsletterForm"
import { MediaContext } from "@avldev/gatsby-theme-core-ui/context"
import { TMediaContext } from "@avldev/gatsby-theme-core-ui/contextTypes"

export type ModalState = `showForm` | `showDownload`

export type DownloadEbookProps = {
  btnProps?: ButtonProps
  onCloseModal?: () => void
  onOpenModal?: () => void
  ref?: React.MutableRefObject<HTMLButtonElement>
}

function DownloadEbookModal({
  isOpen,
  onClose,
}: UseDisclosureProps): JSX.Element {
  const [modalState, setModalState] = React.useState<ModalState>(`showForm`)
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false)
  const showFormRef = React.useRef<HTMLDivElement>()
  const showDownloadRef = React.useRef<HTMLDivElement>()
  const { reduceMotion } = React.useContext<TMediaContext>(MediaContext)

  const dur = reduceMotion ? 0.0 : 0.3

  React.useEffect(() => {
    if (!isOpen) {
      setModalState(`showForm`)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (modalState === `showDownload` && showDownloadRef?.current) {
      gsap.from(showDownloadRef.current, {
        duration: dur,
        x: `100%`,
      })
    } else if (modalState === `showForm` && showFormRef?.current) {
      gsap.from(showFormRef.current, {
        duration: dur,
        x: `-100%`,
      })
    }
  }, [modalState])

  const toggleModalState = (): void => {
    if (modalState === `showForm`) {
      gsap.to(showFormRef.current, {
        duration: dur,
        opacity: 0.25,
        x: `-100%`,
        onComplete: () => {
          setModalState(`showDownload`)
          setFormSubmitted(false)
        },
      })
    } else {
      gsap.to(showDownloadRef.current, {
        duration: dur,
        opacity: 0.25,
        x: `100%`,
        onComplete: () => {
          setModalState(`showForm`)
        },
      })
    }
  }

  const { planBook } = useStaticQuery(graphql`
    query {
      planBook: contentfulAsset(
        contentful_id: { eq: "6NhsOy6q2e7N5uZAt6dJLL" }
      ) {
        file {
          url
          details {
            size
          }
          fileName
        }
      }
    }
  `)

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="685px" overflowX="hidden">
        <ModalHeader borderTopRadius="lg" m={0} p={0}>
          <Box pos="relative" pt="12px" w="100%">
            <StaticImage
              alt="A close-up photo of a printed Sundog home plan."
              src="../images/plan-book-cover.png"
              height={300}
              style={{
                borderTopLeftRadius: `var(--chakra-radii-md)`,
                borderTopRightRadius: `var(--chakra-radii-md)`,
                width: `100%`,
              }}
            />
            <Flex
              align="center"
              bg="blackAlpha.600"
              flexDir="column"
              h="300px"
              justify="center"
              left="9px"
              pos="absolute"
              textAlign="center"
              top="12px"
              w="calc(100% - 18px)"
            >
              <Text
                color="white"
                fontFamily="heading"
                fontSize="4xl"
                fontWeight="bold"
              >
                Get The Sundog Homes Plan Book
              </Text>
            </Flex>
            <ModalCloseButton color="gray.300" size="lg" />
          </Box>
        </ModalHeader>
        <ModalBody>
          {modalState === `showForm` && (
            <Flex
              align="center"
              flexDir="column"
              h="100%"
              justify="space-evenly"
              ref={showFormRef}
              w="100%"
            >
              <Text mb={4} w="275px">
                Sign up for the Sundog Report, our monthly newsetter showcasing
                builds, news, and industry trends.
              </Text>
              <NewsletterForm
                buttonText="Submit"
                context={[]}
                eventLabel="eBook Popup"
                includeName
                layout="block"
                onSubmit={() => setFormSubmitted(true)}
              />
              <Box>
                <Button
                  colorScheme={formSubmitted ? `green` : `gray`}
                  color={formSubmitted ? `white` : `gray.700`}
                  mt={6}
                  onClick={toggleModalState}
                  rightIcon={formSubmitted ? <Icon as={FaArrowRight} /> : null}
                  variant={formSubmitted ? `solid` : `link`}
                >
                  {formSubmitted
                    ? `Continue to Download`
                    : `No thanks, just download the plan book`}
                </Button>
              </Box>
            </Flex>
          )}
          {modalState === `showDownload` && (
            <Flex
              align="center"
              flexDir="column"
              h="100%"
              justify="space-evenly"
              ref={showDownloadRef}
              w="100%"
            >
              <Flex
                bg="gray.100"
                flexDir="column"
                justify="center"
                p={6}
                rounded="lg"
              >
                <Image alt="The PDF icon" src="/pdf-icon.svg" h="80px" />
                <Button
                  as="a"
                  colorScheme="green"
                  download={planBook.file.fileName}
                  h="60px"
                  href={`https:` + planBook.file.url}
                  id="Plan Book"
                  leftIcon={<Icon as={FaDownload} />}
                  mb={2}
                  mt={6}
                  mx="auto"
                  size="lg"
                  target="_blank"
                  variant="solid"
                >
                  Download Plan Book
                </Button>
                <Text
                  color="gray.600"
                  fontSize="xs"
                  mt={1}
                  mx="auto"
                  textAlign="center"
                >
                  {planBook.file.fileName} (
                  {`${planBook.file.details.size / 1_000_000}`.substring(0, 4)}
                  mb)
                </Text>
              </Flex>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter justifyContent="center">
          {modalState === `showDownload` && (
            <Button mr={3} onClick={toggleModalState} size="sm" variant="link">
              Back
            </Button>
          )}
          <Button onClick={onClose} size="sm" variant="link">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function DownloadEbookButton(props?: DownloadEbookProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const openModal = (): void => {
    props.onOpenModal?.()
    onOpen()
  }

  const closeModal = (): void => {
    onClose()
    props.onCloseModal?.()
  }

  return (
    <>
      <Button
        border="1px solid"
        borderColor="teal.400"
        borderRadius="none"
        borderTopLeftRadius="xl"
        colorScheme="teal"
        fontSize={[`sm`, `md`, `lg`]}
        leftIcon={<Icon as={FaBook} />}
        shadow="lg"
        zIndex={3}
        {...props.btnProps}
        onClick={openModal}
      >
        Download Our Plan Book
      </Button>
      <DownloadEbookModal isOpen={isOpen} onClose={closeModal} />
    </>
  )
}

export default DownloadEbookButton
export { DownloadEbookModal }
